<template>
  <div>
    <a-card title="收货记录详情">
      <!-- <a-button slot="extra" type="primary" ghost icon="qrcode" @click="printBatch" style="margin-right: 8px;">批量打印标签
      </a-button> -->
      <!-- <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
        <a-icon type="printer" />打印
      </a-button> -->
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />返回 </a-button>
      <section id="printContent">
        <a-spin :spinning="orderLoading">
          <a-descriptions bordered>
            <a-descriptions-item label="入库通知单号">
              {{ receiptRecordItem.receipt_order_number }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ receiptRecordItem.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="订单类型">
              {{ receiptRecordItem.order_type_name }}
            </a-descriptions-item>
            <a-descriptions-item label="客户单号">
              {{ receiptRecordItem.external_number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ receiptRecordItem.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="收货总数量">
              {{ receiptRecordItem.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ receiptRecordItem.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="stockInMaterialItems"
            :loading="materialLoading"
            :pagination="false"
          >
            <!-- <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button icon="qrcode" @click="printLabel(item)">打印标签</a-button>
              </a-button-group>
            </div> -->
            <template slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <a-space>
                <img
                  v-for="item in record.receipt_record_image_items"
                  :src="item.file"
                  :key="item.id"
                  width="100"
                  height="100"
                  @click="preview(item.file)"
                  style="cursor: pointer"
                />
              </a-space>
            </template>
          </a-table>
        </div>
      </section>
    </a-card>

    <a-modal :visible="previewVisible" title="图片" :footer="null" @cancel="previewVisible = false">
      <img alt="image" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getLodop } from "@/assets/LodopFuncs";
import { receiptRecordRetrieve, receiptRecordMaterials } from "@/api/stockIn";
import { columns } from "./columns";

export default {
  data() {
    return {
      columns,
      orderLoading: false,
      materialLoading: false,
      receiptRecord: undefined,
      receiptRecordItem: {},
      stockInMaterialItems: [],
      previewVisible: false,
      previewImage: "",
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    batchLabelWidth() {
      return this.$store.state.user.barConfig.batchLabelWidth;
    },
    batchLabelLength() {
      return this.$store.state.user.barConfig.batchLabelLength;
    },
  },
  methods: {
    initData() {
      this.receiptRecord = this.$route.query.id;

      this.orderLoading = true;
      receiptRecordRetrieve({ id: this.receiptRecord })
        .then((data) => {
          this.receiptRecordItem = data;
        })
        .finally(() => {
          this.orderLoading = false;
        });

      this.materialLoading = true;
      receiptRecordMaterials({ id: this.receiptRecord })
        .then((data) => {
          this.stockInMaterialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    printLabel(item) {
      if (!this.batchLabelLength || !this.batchLabelWidth) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }
      // let LODOP = getLodop()
      // LODOP.PRINT_INIT("")
      let codeInfo = {
        id: item.batch,
        number: item.batch_number,
        material: item.material,
        material_number: item.material_number,
        material_name: item.material_name,
        production_date: item.production_date,
        expiration_date: item.expiration_date,
      };
      // let widthE = this.batchLabelLength / 3
      // let heightE = this.batchLabelWidth/3
      // LODOP.ADD_PRINT_TEXT(10,34,this.batchLabelLength,heightE,item.material_name);
      // LODOP.ADD_PRINT_TEXT(heightE, 34, widthE, 20,item.total_quantity);
      // LODOP.ADD_PRINT_TEXT(heightE + 20, 34, widthE,heightE,item.batch_number);
      // LODOP.ADD_PRINT_BARCODE(heightE, 34 + widthE, widthE*2, heightE*2, "QRCode", JSON.stringify(codeInfo));
      // // LODOP.ADD_PRINT_BARCODE(38, 34, this.batchLabelLength, this.batchLabelWidth, "QRCode", JSON.stringify(codeInfo));
      // LODOP.SET_PRINT_STYLEA(0, 'QRCodeVersion', '10')
      // LODOP.SET_PRINT_STYLEA(0, 'DataCharset', 'UTF-8')
      // LODOP.PREVIEW()

      let LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.batchLabelLength}mm`, `${this.batchLabelWidth}mm`);
      LODOP.ADD_PRINT_BARCODE(
        `${this.batchLabelWidth * 0.2}mm`,
        `${this.batchLabelLength * 0.25}mm`,
        `${this.batchLabelLength * 0.5}mm`,
        `${this.batchLabelWidth * 0.5}mm`,
        "QRCode",
        JSON.stringify(codeInfo)
      );
      LODOP.ADD_PRINT_TEXT(
        `${this.batchLabelWidth * 0.65}mm`,
        `${this.batchLabelLength * 0.25}mm`,
        `${this.batchLabelLength * 0.5}mm`,
        `${this.batchLabelWidth * 0.05}mm`,
        `产品名称: ${item.material_name}`
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(
        `${this.batchLabelWidth * 0.71}mm`,
        `${this.batchLabelLength * 0.25}mm`,
        `${this.batchLabelLength * 0.5}mm`,
        `${this.batchLabelWidth * 0.05}mm`,
        `数量: ${item.receipt_quantity}`
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(
        `${this.batchLabelWidth * 0.76}mm`,
        `${this.batchLabelLength * 0.25}mm`,
        `${this.batchLabelLength * 0.5}mm`,
        `${this.batchLabelWidth * 0.05}mm`,
        `批次: ${item.batch_number}`
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.PREVIEW();
    },
    printBatch() {
      if (!this.stockInMaterialItems.length) {
        this.$message.warning("暂无打印的数据!");
        return false;
      }
      if (!this.batchLabelLength || !this.batchLabelWidth) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }
      let LODOP = getLodop();
      let widthE = this.batchLabelLength / 3;
      let heightE = this.batchLabelWidth / 3;
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.batchLabelLength}mm`, `${this.batchLabelWidth}mm`);
      this.stockInMaterialItems.map((item, idx) => {
        let codeInfo = {
          id: item.batch,
          number: item.batch_number,
          material: item.material,
          material_number: item.material_number,
          material_name: item.material_name,
          production_date: item.production_date,
          expiration_date: item.expiration_date,
        };
        // LODOP.ADD_PRINT_TEXT(10 + idx * (this.batchLabelWidth + 10), 34, this.batchLabelLength, heightE, item.material_name);
        // LODOP.ADD_PRINT_TEXT(heightE + idx * (this.batchLabelWidth + 10), 34, widthE, 20, item.total_quantity);
        // LODOP.ADD_PRINT_TEXT(heightE + 20 + idx * (this.batchLabelWidth + 10), 34, widthE, heightE, item.batch_number);
        // LODOP.ADD_PRINT_BARCODE(heightE + idx * (this.batchLabelWidth + 10), 34 + widthE, widthE * 2, heightE * 2, "QRCode", JSON.stringify(codeInfo));
        // // LODOP.ADD_PRINT_BARCODE(38 + idx*(this.batchLabelWidth+20), 34, this.batchLabelLength, this.batchLabelWidth, "QRCode", JSON.stringify(codeInfo));
        // LODOP.SET_PRINT_STYLEA(0, 'QRCodeVersion', '10')
        // LODOP.SET_PRINT_STYLEA(0, 'DataCharset', 'UTF-8')

        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${this.batchLabelWidth * 0.2}mm`,
          `${this.batchLabelLength * 0.25}mm`,
          `${this.batchLabelLength * 0.5}mm`,
          `${this.batchLabelWidth * 0.5}mm`,
          "QRCode",
          JSON.stringify(codeInfo)
        );
        LODOP.ADD_PRINT_TEXT(
          `${this.batchLabelWidth * 0.65}mm`,
          `${this.batchLabelLength * 0.25}mm`,
          `${this.batchLabelLength * 0.5}mm`,
          `${this.batchLabelWidth * 0.05}mm`,
          `产品名称: ${item.material_name}`
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        LODOP.ADD_PRINT_TEXT(
          `${this.batchLabelWidth * 0.71}mm`,
          `${this.batchLabelLength * 0.25}mm`,
          `${this.batchLabelLength * 0.5}mm`,
          `${this.batchLabelWidth * 0.05}mm`,
          `数量: ${item.total_quantity}`
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        LODOP.ADD_PRINT_TEXT(
          `${this.batchLabelWidth * 0.76}mm`,
          `${this.batchLabelLength * 0.25}mm`,
          `${this.batchLabelLength * 0.5}mm`,
          `${this.batchLabelWidth * 0.05}mm`,
          `批次: ${item.batch_number}`
        );
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      });
      LODOP.PREVIEW();
    },
    preview(imageUrl) {
      this.previewVisible = true;
      this.previewImage = imageUrl;
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 300
  },
  {
    title: "产品英文名称",
    dataIndex: "material_english_name",
    key: "material_english_name",
    width: 300
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
  },
  {
    title: "收货数量",
    dataIndex: "receipt_quantity",
  },
  {
    title: "单位",
    dataIndex: "material_unit",
  },
  {
    title: "计费量",
    dataIndex: "charge_value",
  },
  {
    title: "计费单位",
    dataIndex: "charge_unit",
  },
  {
    title: "质检状态",
    dataIndex: "status_display",
  },
  {
    title: "生产日期",
    dataIndex: "production_date",
  },
];
